@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.aggregate-controller {
  color: $color-white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $color-gray-400;
  min-height: 32px;
  height: 32px;
  background: $color-gray-700;
  padding: 0 8px;
  border-bottom-left-radius: $trade-page-border-radius;
  border-bottom-right-radius: $trade-page-border-radius;

  span {
    display: flex;
  }

  &__increase {
    border-right: 1px solid $color-gray-500;
  }

  &__decrease {
    border-left: 1px solid $color-gray-500;
  }

  &__increase,
  &__decrease {
    height: 100%;
    border: none;
    color: $color-white;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 21px;
    background-color: $color-gray-500;
    transition: background-color 0.2s ease-in-out;
    outline: inherit;

    &:hover {
      background-color: $color-gray-400;
    }

    &:active {
      background-color: $color-gray-300;
    }

    &:disabled {
      background-color: $color-gray-500;
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__btn-container {
    display: flex;
    gap: 5px;
  }
}
