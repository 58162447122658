@import '@ping/assets/scss/theme/variables';

.slider-milestone-item {
  --milestone-circle-size: 0.5rem;

  display: flex;
  justify-content: center;
  inline-size: var(--milestone-circle-size);
  block-size: var(--milestone-circle-size);
  border-radius: 50%;
  background: $color-gray-700;
  border: var(--milestone-item-border-size) solid $color-gray-300;
  cursor: pointer;
  position: relative;
  margin: 0;

  &[data-is-filled='true'] {
    border-color: $color-gray-100;
  }

  &__label {
    position: absolute;
    inset-block-start: 50%;
    z-index: 1;
    margin-block-start: 0.75rem;
    font-size: 12px;
    font-weight: 400;
    color: $color-gray-200;
    inline-size: fit-content;
  }

  &:first-of-type &__label {
    inset-inline-start: calc(var(--milestone-circle-size) * -0.5);
  }

  &:last-of-type &__label {
    inset-inline-end: calc(var(--milestone-circle-size) * -0.5);
  }
}
