@import '@ping/assets/scss/theme/variables';

$input-field-block-size: 3rem;
$input-field-border-radius: 3px;
$input-field-inline-padding: 1rem;
$input-field-block-padding: 0.75rem;

.label {
  --text-color: #{$color-gray-100};
  --icon-rotation: 0deg;

  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.2s;

  &[aria-expanded='true'] {
    --text-color: #{$color-white};
    --icon-rotation: -180deg;
  }

  &__text {
    color: var(--text-color);
    transition: inherit;
  }

  &__icon {
    transform: rotate(var(--icon-rotation));
    transition: inherit;
  }
}

.variants-list {
  padding: 0.5rem 0px 0.75rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;

  inline-size: 7.5rem;
  background: $color-gray-600;
  border-radius: 3px;

  &__item {
    padding: 0.5rem 1rem;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
    cursor: pointer;

    &[aria-selected='true'] {
      background: $color-gray-400;
    }
  }
}

.amount-number {
  &::placeholder {
    color: $color-gray-100;
    opacity: 0.25;
  }

  &:disabled {
    cursor: not-allowed;
  }
}
