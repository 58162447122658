@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.tabs {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  inline-size: 100%;

  &[data-variant='primary'] {
    gap: var(--tabs-gap, 3rem);

    --tabs-divider-size: 0.6px; // 0.5px won't show in browser
    --tabs-divider-color: #{$color-gray-300};
    --tab-padding: 1rem 1.5rem;
    --tab-selection-indicator-color: #{$color-primary};
    --tab-selection-indicator-size: 4px;
    --tab-selection-indicator-roundness: 2px;
  }

  &[data-variant='secondary'] {
    gap: var(--tabs-gap, 2rem);

    --tabs-divider-size: 2px;
    --tabs-divider-color: #{$color-gray-400};
    --tab-padding: 0.75rem 0.5rem;
    --tab-selection-indicator-color: #{$color-primary};
    --tab-selection-indicator-size: 2px;
    --tab-selection-indicator-roundness: 0.5px;

    @include media-breakpoint-up('md') {
      --tab-padding: 0.75rem 1.5rem;
    }
  }

  &[data-variant='tertiary'] {
    --tab-padding: 0.625rem 0.75rem;
    --tab-font-weight: 400;
  }

  &__wrapper {
    inline-size: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    //
    // hide divider line overflow
    border-end-start-radius: var(--tab-selection-indicator-roundness);
    border-end-end-radius: var(--tab-selection-indicator-roundness);

    @include media-breakpoint-up('md') {
      justify-content: flex-start;
    }

    &::before {
      content: '';
      position: absolute;
      inset-block-end: 0;
      inline-size: 100%;

      background-color: var(--tabs-divider-color);
      block-size: var(--tabs-divider-size, 0);
    }
  }

  &__header {
    inline-size: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    isolation: isolate;
    overflow-x: auto;
    //
    // hide horizontal scrollbar
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    &::-webkit-scrollbar {
      display: none;
    }

    &[aria-orientation='horizontal'] {
      flex-direction: row;
    }
    &[data-even='true'] > * {
      flex: 1;
    }

    @include media-breakpoint-up('md') {
      justify-content: flex-start;
    }
  }

  &__selection {
    inline-size: var(--tab-selection-inline-size); // will be set in React component
    transform: translateX(var(--tab-selection-offset-x)); // will be set in React component
    will-change: transform, width;
    transition: all 0.2s, transform 0.2s, width 0.1s;

    position: absolute;
    inset: 0;
    z-index: -1;

    &[data-is-focus-visible='true'] {
      background-color: $color-primary-light;
    }

    &::after {
      content: '';
      position: absolute;
      inset-block-end: 0;
      z-index: 1;
      inline-size: 100%;

      block-size: var(--tab-selection-indicator-size, 0);
      background-color: var(--tab-selection-indicator-color);
      border-radius: var(--tab-selection-indicator-roundness, 0);
    }
  }
}
