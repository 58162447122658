@import '@ping/assets/scss/theme/variables';

.trading-widget-form-balance {
  font-family: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: end;
  overflow-wrap: anywhere;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  letter-spacing: -0.01em;
  margin: 0;

  color: $color-gray-200;

  &:empty {
    block-size: 1.125rem; // to prevent the UI shifting on loading
  }
}
