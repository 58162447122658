@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';

.balance-table {
  overflow: auto;
  // height = (trading records height in mobile) - (tab header height) - (available balance height in mobile) - (border height)
  height: calc($trading-records-height-mobile - $card-header-height - 96px - 2px);
}

@include media-breakpoint-up('sm') {
  .balance-table {
    // height = (trading records height in mobile) - (tab header height) - (border height)
    height: calc($trading-records-height-mobile - $card-header-height - 2px);
  }
}

@include media-breakpoint-up('lg') {
  .balance-table {
    // height = (trading records height) - (tab header height) - (border height)
    height: calc($trading-records-height - $card-header-height - 2px);
  }
}
