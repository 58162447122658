.slider-root {
  --milestone-item-border-size: 2px;
  --track-inline-size-overflow: 2 * var(--milestone-item-border-size);

  position: relative;
  inline-size: calc(100% - 2 * var(--track-inline-size-overflow));
  translate: calc(var(--track-inline-size-overflow)) 0;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &[data-is-disabled='true'] {
    opacity: 0.4;
    cursor: not-allowed;

    * {
      pointer-events: none;
    }
  }
}
