@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.market-order {
  grid-area: market-order;

  border-radius: $trade-page-border-radius;
  position: relative;

  @include media-breakpoint-up('lg') {
    padding: 1rem;
    border: 1px solid $color-gray-400;
    background-color: $color-gray-700;
  }

  &__loading {
    position: absolute;
    inset: 0%;
    background-color: $color-black;
    opacity: 0;
    visibility: hidden;
    border-radius: $trade-page-border-radius;

    &[aria-hidden='false'] {
      opacity: 1;
      visibility: visible;
    }
    &[aria-hidden='true'] {
      transition: all 0.24s cubic-bezier(0.99, -0.01, 0.81, 0.06);
    }
  }

  &__loading-indicator {
    --rotation-transform-preserve: translate(-50%, -50%);

    position: absolute;
    inset: 50%;
    z-index: 1;
  }
}

@include media-breakpoint-down('lg') {
  .market-order {
    min-height: 30px;
  }
}
