@import '@ping/assets/scss/theme/variables';

.slider-track {
  // NOTE: it'll be controlled by JS inside the component
  --filled-segments-percentage: 0;

  cursor: pointer;

  &:before {
    content: '';
    display: block;
    position: absolute;
    inset-block-start: 50%;
    inline-size: calc(100% + 2 * var(--track-inline-size-overflow));
    translate: calc(-1 * var(--track-inline-size-overflow)) -50%;
    block-size: 4px;
    border-radius: 5rem;
    background-image: linear-gradient(
      to right,
      $color-gray-100 calc(var(--filled-segments-percentage) * 100%),
      $color-gray-300 calc(var(--filled-segments-percentage) * 100%)
    );
  }
}
