@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.market-pairs {
  user-select: none; // Remove the text cursor
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__table-container {
    [class*='table__no-data-icon'] {
      margin-block: 5rem;
    }
  }

  nav {
    border-bottom: none !important;
  }
  .red {
    color: $color-error !important;
  }
  .green {
    color: $color-success !important;
  }
  .white {
    color: $color-white !important;
  }

  .record-span {
    color: $color-gray-100;
    font-weight: 600;
    font-size: 14px;
  }

  .selected-row {
    background: $color-gray-400 !important;
    td {
      span,
      div {
        font-weight: 600;
        color: $color-white;
      }
    }
  }

  .pair-column {
    display: flex;
    align-items: center;
    .pair-icon {
      margin-right: 8px;
      &.not-fill {
        path {
          fill: transparent;
          stroke: $color-gray-100;
        }
      }
    }
  }

  .selected-market-summary-wrapper {
    color: $color-white;
    .selected-market-header {
      display: flex;
      place-items: center;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 12px;
      img {
        width: 18px;
        margin-right: 8px;
      }
    }
    .selected-market-detail {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-weight: 400;
      color: $color-gray-100;
      margin-bottom: 24px;
      .selected-last-price-container {
        .selected-last-price {
          margin-inline-end: 4px;
        }
        .selected-last-price-symbol {
          font-size: 12px;
          margin-left: 4px;
        }
      }
      .selected-24-price {
        .price-percent {
          margin-inline-end: 4px;
        }
      }
    }
  }
}

.hover-effect {
  tbody tr:hover {
    background-color: $color-gray-400 !important;
    cursor: pointer;
  }
}

.last-price {
  .price {
    font-size: 14px;
  }
  .currency {
    font-size: 12px;
    margin-left: 5px;
    font-weight: 400;
  }
}
@include media-breakpoint-up('md') {
  .market-pairs {
    &__table-container {
      overflow: auto;
    }
  }
}
