@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.tab {
  position: relative;
  color: $color-gray-200;
  font-family: inherit;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  outline: unset;
  transition: all 0.36s ease-in-out;

  &:not([aria-disabled='true']):hover {
    color: $color-white;
  }

  &[aria-selected='true'] {
    color: $color-white;
  }

  &[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &[data-variant='primary'] {
    padding: 1rem 1.5rem;
    font-weight: 600;
  }

  &[data-variant='secondary'] {
    padding: 0.75rem 0.5rem;
    font-weight: 600;

    @include media-breakpoint-up('md') {
      padding: 0.75rem 1.5rem;
    }
  }

  &[data-variant='tertiary'] {
    padding: 0.625rem 0.75rem;
    font-weight: 400;
  }
}

.tooltip--content {
  width: 200px;
  padding: 16px;
  color: $color-white;
  font-size: 14px;
}

.tooltip--trigger {
  display: unset;
}
