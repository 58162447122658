@import '@ping/assets/scss/theme/variables';

.warning-modal {
  &__header {
    padding: 2rem;
    display: flex;

    h4 {
      font-size: 20px;
      font-weight: 700;
      line-height: 30px;
      color: $color-white;
    }

    &-icon {
      $close-icon-size: 24px;
      width: $close-icon-size;
      height: $close-icon-size;
      cursor: pointer;
    }
  }

  &__content {
    border-top: none !important;
  }

  &__list {
    list-style-type: disc;
    margin-left: 15px;

    li {
      margin-bottom: 40px;
    }
  }

  &__list-item_title {
    color: $color-white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  &__action {
    padding: 2rem;

    button {
      margin-left: auto;
    }
  }
}
