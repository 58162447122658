@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

@import '@ping/pages/trade/components/MarketOrder/MarketOrderForm/common.styles.scss';

.order-form-modal-triggers {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  position: fixed;
  inset: auto 0 24px;
  z-index: 900;
  background-color: $color-black;
  border-top: 1px solid $color-gray-400;
  height: 84px;

  @include media-breakpoint-up('lg') {
    display: none;
  }

  &__button {
    @extend .form-submit;
    inline-size: 100%;
    height: 48px !important;

    &:disabled {
      border-color: $color-gray-400 !important;
      background: $color-gray-400 !important;
      color: $color-gray-200 !important;
    }
  }
}

.order-form-modal-portal {
  gap: 1.5rem;

  &__header {
    padding: 1.5rem 1.5rem 0;
  }

  &__close-button {
    margin-inline-start: auto;
  }

  &__content {
    padding: 0 1.5rem 3rem;
  }
}
