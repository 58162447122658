@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.selected-market-pair-state {
  display: flex;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;

  &__part {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0.25rem;
    user-select: none;
    width: 100%;
  }

  &__title {
    color: $color-gray-100;

    /* 01 Caption/regular */
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1rem; /* 133.333% */
  }

  &__value {
    margin: 0;
    transition: all 0.24s ease;
    color: $color-white;
    display: flex;
    flex-wrap: wrap;

    /* 01 Body/semibold */
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem; /* 150% */

    &[data-fluctuation='idle'] {
      color: $color-white;
    }
    &[data-fluctuation='up'] {
      color: $color-success;
    }
    &[data-fluctuation='down'] {
      color: $color-error;
    }
  }
  &__value-center {
    align-items: center;
  }
  &__value-end {
    align-items: flex-end;
    text-align: right;
  }
}

@include media-breakpoint-between('lg', 'xl') {
  .selected-market-pair-state {
    flex-direction: column;
    gap: 1rem;
    &__value-center {
      align-items: flex-start;
    }
    &__value-end {
      align-items: flex-start;
    }
  }
}
