@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.open-orders-table {
  overflow: auto;
  // height = (trading records height in mobile) - (tab header height) - (available balance height in mobile) - (border height)
  height: calc($trading-records-height-mobile - $card-header-height - 96px - 2px);
}

.cancel-all-btn {
  &:hover {
    color: white;
  }
  cursor: pointer;
  text-align: center;
  transition: $transition-hover;
}

.cancel-button {
  cursor: pointer;
  justify-content: center;
}

.cancel-icon {
  color: $color-gray-100;
  inline-size: 1rem;
  block-size: 1rem;
  aspect-ratio: 1;
  transition: $transition-hover;

  &:hover {
    color: $color-error;
  }
}

@include media-breakpoint-down('md') {
  .open-orders-table {
    &__no-data-icon-margin-top {
      margin-block-start: 6rem;
      height: calc(100% - 6rem);
    }
  }
}

@include media-breakpoint-up('sm') {
  .open-orders-table {
    // height = (trading records height in mobile) - (tab header height) - (border height)
    height: calc($trading-records-height-mobile - $card-header-height - 2px);
  }
}

@include media-breakpoint-up('lg') {
  .open-orders-table {
    // height = (trading records height) - (tab header height) - (border height)
    height: calc($trading-records-height - $card-header-height - 2px);
  }
}
