@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/breakpoint';

.trading-records {
  grid-area: trading-records;
  border: 1px solid $color-gray-400;
  border-radius: $trade-page-border-radius;
  position: relative;
  overflow: hidden;

  [class*='tabs__header'] {
    height: $card-header-height;
  }

  .trading-records__tabs {
    height: 100%;

    div[role='tabpanel'] {
      overflow: hidden;
      height: 100%;

      .table-container {
        overflow: auto;
        height: 100%;

        table {
          overflow-x: unset;
        }
      }
    }
  }
  &__available-balance {
    background-color: inherit;
    position: absolute;
    inset-block-start: 2.75rem; // tablist block size
    z-index: 1;
    padding-block: calc((6rem - 4rem) * 0.5); // half of (space size - mobile balance block size)
    padding-inline: 0.75rem;
    margin-inline-start: auto;
    block-size: 6rem;
    inline-size: 100%;

    @include media-breakpoint-up('md') {
      inset-inline-end: 0;
      inset-block-start: 0.75rem;
      transform: translateY(0.5rem * -0.5);
      padding-block: 0;
      padding-inline-end: 1.5rem;
      block-size: auto;
      inline-size: auto;
    }

    &:empty + .trading-records__tabs {
      div[role='tabpanel'] {
        padding-block-start: 0;
      }
    }
  }

  &__tabs {
    background-color: $color-gray-600;

    & > div[role='tabpanel'] {
      background-color: $color-black;

      @include media-breakpoint-up('md') {
        padding-block-start: 0;
      }
    }

    div[role='tab'] {
      &:nth-of-type(2) {
        // NOTE: the first-of-type select selection div
        padding-inline-start: 1rem;
      }
      &:last-of-type {
        padding-inline-end: 1rem;
      }
    }
  }
}

@include media-breakpoint-down('md') {
  .trading-records {
    .trading-records__tabs {
      div[role='tabpanel'] {
        .table-container {
          margin-block-start: calc(6rem - 4px);
        }
      }
    }
  }
}
