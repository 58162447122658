@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

/* NOTE: This file controls the height and width of every component in the dashboard. */
/* If any changes need to be made, please make them here in this file (no need to add or change height or width to other components). */

.main-container {
  padding: 1rem;
  padding-block-end: 6.6rem; // mobile space for the trading form buttons.
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @include media-breakpoint-up('lg') {
    display: grid;
    padding-block-end: 2rem;
    grid-template-columns: min(24%, 30rem) auto min(24%, 30rem);
    grid-template-rows: 35rem 32rem $trading-records-height;
    grid-template-areas:
      'market-order trading-chart order-book'
      'market-order market-history order-book'
      'trading-records trading-records trading-records';
  }
}

.trade-widgets-tabs {
  --tabs-gap: 1.5rem;

  div[role='tabpanel'] > * {
    block-size: 34.5rem;
  }
}
