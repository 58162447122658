@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.form-structure-fee-row {
  grid-column: 1 / -1;
  inline-size: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__text {
    inline-size: auto;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $color-gray-100;
  }

  &__tooltip {
    margin-inline: 8px auto;
    display: inline;
    gap: 8px;
  }

  &__icon {
    color: $color-gray-100;
    transition: $transition-hover;

    &:hover {
      color: $color-white;
      cursor: help;
    }
  }

  &__divider {
    inline-size: 1px;
    block-size: 1rem;
    background-color: $color-gray-300;
  }
}

.form-structure-tooltip-content {
  color: $color-white;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
}
