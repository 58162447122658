@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.scrollable-tab-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: scroll;
  user-select: none;
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none;
  }

  &:after {
    content: '';
    display: inline-block;
    position: sticky;
    /* buggy when wrapper has a border */
    right: -1px;
    height: 40px;
    min-width: 48px;
    background: linear-gradient(90deg, rgba(19, 23, 34, 0) 0%, $color-black 81.79%);
    z-index: 98;
  }

  .scrollable-tab-item {
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: start;
    border-radius: 3px;
    min-width: 64px;
    padding: 8px 11px;
    color: $color-white;
    background: $color-gray-700;
    box-sizing: border-box;
    font-size: 14px;
    transition: $transition-hover;
    &:hover,
    &-selected {
      cursor: pointer;
      background: $color-gray-500;
    }
  }
}
