@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.not-fill {
  path {
    fill: transparent;
    stroke: $color-gray-100;
    transition: $transition-hover;
  }
  &:hover {
    path {
      fill: $color-gray-100;
    }
  }
}
