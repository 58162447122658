@import '@ping/assets/scss/theme/variables';

.market-order-pair {
  display: grid;
  gap: 1.5rem;
  z-index: 20; // z-index of the tart trading today overlay is 10

  &__assets {
    display: flex;
    align-items: flex-start;
    padding-block-end: 1rem;
    border-block-end: 1px solid $color-gray-300;
    z-index: 11;
  }

  &__trigger {
    inline-size: 2rem;
    block-size: 2rem;
    aspect-ratio: 1;
    border-radius: 0.5rem;
    border: 1px solid $color-gray-300;
    display: grid;
    place-content: center;
    transition: border-color 0.36s;
    cursor: pointer;

    &[aria-expanded='true'] {
      border-color: $color-gray-100;
    }

    &:hover {
      border-color: $color-gray-200;
    }
  }

  &__trigger-icon {
    color: $color-white;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }
}
