@import '@ping/assets/scss/theme/variables';

.market-pairs-dropdown-trigger {
  inline-size: 2rem;
  block-size: 2rem;
  aspect-ratio: 1;
  border-radius: 0.5rem;
  border: 1px solid $color-gray-300;
  display: grid;
  place-content: center;
  transition: border-color 0.36s;

  &:hover {
    border-color: $color-gray-200;
  }

  &[aria-expanded='true'] {
    border-color: $color-gray-100;
  }

  &__icon {
    color: $color-white;
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
    transition: rotate 0.36s;

    &[data-expanded='true'] {
      rotate: 180deg;
    }
  }
}

.market-pairs-dropdown-underlay {
  position: absolute;
  inset: 0;
  z-index: -1;

  &[aria-hidden='true'] {
    display: none;
  }
}

.market-pairs-dropdown-menu {
  padding: 1rem;
  inline-size: 100%;
  max-block-size: fit-content;
  border-radius: 0rem 0rem 3px 3px;
  border: 1px solid $color-gray-300;
  background: $color-black;
  box-shadow: 0 0.75rem 2rem 0 rgba(0 0 0 / 0.25);
  position: absolute;
  inset: 4rem 0 0;
  z-index: 1;
  transition: translate 0.36s, opacity 0.24s;

  &[aria-hidden='true'] {
    translate: 0 -1rem;
    z-index: -1;
    opacity: 0;
    block-size: 0;
    overflow: hidden;
  }

  [class*='market-pairs__table-container'] {
    max-block-size: 50rem;
  }
}
