@import '@ping/assets/scss/theme/variables';

.empty-view-text {
  color: $color-white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
}
