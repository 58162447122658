@import '@ping/assets/scss/theme/variables';

.tab-panel {
  color: $color-white;
  inline-size: 100%;

  &[aria-selected='false'] {
    display: none;
    pointer-events: none;
  }
}
