@import '@ping/assets/scss/theme/variables';

.popover-content {
  color: $color-white;
  overflow: hidden;
  outline: none;

  &[data-placement*='bottom'] {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
    border-block-start: 1px solid $color-gray-300;
  }

  &[data-placement*='top'] {
    border-end-start-radius: 0;
    border-end-end-radius: 0;
    border-block-end: 1px solid $color-gray-300;
  }
}
