@import '@ping/assets/scss/theme/variables';

.slider-thumb {
  $thumb: &;

  --touch-area-size: 2rem;

  inset-block-start: 50%;
  z-index: 10;
  inline-size: var(--touch-area-size);
  block-size: var(--touch-area-size);
  border-radius: 50%;
  display: grid;
  place-content: center;
  cursor: grab;
  transition: background 0.2s ease-in-out;

  &:active {
    cursor: grabbing;
  }

  &[data-is-dragging='true'] {
    background: rgba($color: $color-primary, $alpha: 0.5);
  }

  &[data-is-hovered='true'] {
    background: rgba($color: $color-primary, $alpha: 0.1);
  }

  &[data-is-focus-visible='true'] {
    background: rgba($color: $color-primary, $alpha: 0.5);
  }

  &__tooltip {
    $tooltip-background-color: $color-gray-600;
    $triangle-size: 0.5rem;
    $vertical-space: 0.5rem;

    block-size: 2.5rem;
    inline-size: 4rem;
    background-color: $tooltip-background-color;
    display: grid;
    place-content: center;
    border-radius: 3px;
    position: absolute;
    inset: calc(-100% - $triangle-size - $vertical-space) -50% 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;

    &[aria-hidden='false'] {
      visibility: visible;
    }

    &::before {
      content: '';
      position: absolute;
      inset: 100% 50% 0;
      translate: -50% 0;
      border-style: solid;
      border-width: $triangle-size $triangle-size 0;
      border-color: $tooltip-background-color transparent transparent;
      inline-size: 0;
      block-size: 0;
    }
  }

  &__output {
    font-size: 1rem;
    color: $color-white;
  }

  &__handle {
    inline-size: 0.625rem;
    block-size: 0.625rem;
    border-radius: 50%;
    background: $color-gray-100;
    border: var(--milestone-item-border-size) solid transparent;
    transition: all 0.2s;

    #{$thumb}[data-is-hovered='true'] & {
      scale: 1.2;
    }
  }
}
