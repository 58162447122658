@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/theme/variables';

.market-order-form {
  position: fixed;
  inset: auto 0 0;
  z-index: 900;
  padding: 1rem 1rem 2rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  border-top: 1px solid $color-gray-300;
  background-color: $color-black;
  margin-block-start: 24px;

  @include media-breakpoint-up('lg') {
    display: block;
    position: initial;
    z-index: auto;
    border: none;
    background: none;
    padding: 0;
  }
}

.overlay {
  position: absolute;
  padding: 32px;
  border-radius: $trade-page-border-radius;
  color: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  inset: 0;
  z-index: 10;
  background: linear-gradient(180deg, rgb(19 23 34 / 0.86) 14.1%, rgba(19 23 34 / 0.7) 100%);

  @include media-breakpoint-down('lg') {
    /* there is also a logic in JS to not render it on mobile and tablet */
    display: none;
  }

  &__title {
    margin-block-end: 0.5rem;

    /* Headlines/H4 */
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 150% */
    letter-spacing: -0.0125rem;
  }

  &__description {
    max-inline-size: 26rem;
    color: $color-white;
    text-align: center;
    text-wrap: balance;
    margin-block-end: 2rem;

    /* 01 Body/regular */
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 150% */
  }

  &__link {
    color: $color-primary-light;
    text-decoration: underline;
    transition: $transition-hover;
    font-weight: 600;

    &:hover {
      color: $color-primary;
    }
  }
}
