@import '@ping/assets/scss/theme/variables';

.slider-label {
  color: $color-white;

  /* 01 Body/semibold */
  font-family: inherit;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  /* 150% */
}
