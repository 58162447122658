@import '@ping/assets/scss/theme/variables';

.status-indicator-wrapper {
  padding: 8px;
  display: inline-flex;
}

.status-indicator {
  width: 4px;
  height: 4px;
  border-radius: 10px;
  display: inline-flex;
}

.status-tooltip-wrapper {
  display: flex;
  align-items: center;

  :global .tooltip-inner {
    padding: 8px 24px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
  }

  .status-indicator {
    margin-right: 8px;
  }
}

.help-icon-tooltip {
  color: $color-gray-200;
  margin-left: 6px;

  svg {
    cursor: pointer;
  }
}

.status-text {
  text-transform: capitalize;
}

.bg {
  &--green {
    background: $color-success;
  }

  &--red {
    background: $color-error;
  }

  &--yellow {
    background: $color-warning;
  }

  &--gray {
    background: $color-gray-200;
  }

  &--blue {
    background: $color-primary;
  }
}
