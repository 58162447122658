@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.total-stats-cell {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $color-white;
  overflow-x: auto;
  white-space: nowrap;
}

.amount-slider {
  margin-block: 1.25rem 0.5rem;
  block-size: 1.75rem;
}

.form-field {
  position: relative;
  isolation: isolate;

  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form-message {
  /* Caption 01 / Regular */
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  margin: 0;
  align-self: flex-end;
  color: $color-error-light;
  transition: all 0.24s;

  &[aria-hidden='true'] {
    display: none;
  }
}

.asset-name {
  color: $color-gray-100;

  /* 01 Body/regular */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  /* 150% */
}

.total-text-market {
  display: flex;
  flex-wrap: wrap;

  span:first-child {
    margin-inline-end: 4px;
  }
}

.tooltip-info {
  z-index: 1;
}

.confirm-order-modal {
  border-radius: 1rem 1rem 0rem 0rem;
  border: 1px solid $color-gray-400;

  @include media-breakpoint-up('md') {
    inline-size: 36rem;
    border-radius: 1rem;
  }

  &__header {
    padding: 1rem;
    justify-content: flex-end;
    gap: 1.5rem;

    @include media-breakpoint-up('lg') {
      padding: 2rem 2rem 1rem 2rem;
      justify-content: space-between;
    }
  }

  &__title {
    display: none;

    @include media-breakpoint-up('lg') {
      display: block;

      /* Headlines/H4 */
      font-family: inherit;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.875rem;
      /* 150% */
      letter-spacing: -0.0125rem;
    }
  }

  &__content {
    align-self: stretch;
    padding: 0rem 1rem 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem;

    @include media-breakpoint-up('lg') {
      padding: 1rem 2rem 2rem 2rem;
      gap: 2rem;
    }
  }

  &__title-mobile {
    /* Headlines/H4 */
    font-family: inherit;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem;
    /* 150% */
    letter-spacing: -0.0125rem;

    @include media-breakpoint-up('lg') {
      display: none;
    }
  }

  &__details {
    align-self: stretch;
    padding: 1.25rem 1.25rem 1.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    border-radius: 1.25rem;
    border: 1px solid $color-gray-400;
  }

  &__details-heading {
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 1rem;

    background: inherit !important;
  }

  &__details-title {
    color: $color-white;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    flex-grow: 1;

    /* 01 Body/semibold */
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5rem;
    /* 150% */
  }

  &__detail-list {
    margin: 0;
    margin-block-start: 1rem;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    justify-items: stretch;
    row-gap: 0.25rem;
  }

  &__detail-head {
    color: $color-white;
    overflow: hidden;
    text-overflow: ellipsis;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
  }

  &__detail-data {
    color: $color-white;
    text-align: end;
    margin: 0;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
  }

  &__detail-divider {
    //                          row-gap-space
    margin-block: calc(1.5rem - 2 * 0.25rem);
  }

  &__actions {
    padding-block-start: 1.25rem;
    padding-inline: 1rem;
    gap: 0;

    @include media-breakpoint-up('lg') {
      padding: 2rem;
      gap: 1.5rem;
    }
  }

  &__cancel {
    display: none;

    @include media-breakpoint-up('lg') {
      display: flex;
    }
  }

  &__confirm {
    min-inline-size: 100%;
    max-inline-size: 100%;

    @include media-breakpoint-up('lg') {
      min-inline-size: unset;
      max-inline-size: unset;
    }
  }
}

.confirm-order-timer {
  &__title {
    color: $color-gray-100;
    text-align: end;
    white-space: nowrap;

    /* 02 Body/regular */
    font-family: inherit;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem;
    /* 142.857% */
  }

  &__remaining {
    inline-size: 1.25rem;
    display: inline-flex;
    place-content: center;
  }
}
