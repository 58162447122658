@import '@ping/assets/scss/theme/variables';

.market-pairs-modal-portal {
  background-color: $color-black;

  &__header {
    padding: 1.75rem 1rem;
  }

  &__content {
    padding: 1rem;
  }
}
