@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.selected-market-pair {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  flex: 1;
  user-select: none;

  &__icon {
    inline-size: 2rem;
    block-size: 2rem;
    aspect-ratio: 1;
    pointer-events: none;
  }

  &__name {
    color: $color-white;

    /* Headlines/H4 */
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.875rem; /* 150% */
    letter-spacing: -0.0125rem;
  }
}
