@import '@ping/pages/trade/components/MarketOrder/MarketOrderForm/common.styles.scss';

.form-structure-submit {
  @extend .form-submit;

  &-modal {
    display: flex;
    width: 448px;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    // Fixed Modal height on the large screen
    height: auto;
    min-block-size: unset;
    border-radius: 3px;
    border: 1px solid $color-gray-300;
    background: $color-gray-700;
    position: relative;

    &__header {
      align-self: stretch;
      display: grid;
      place-content: center;
      margin-block-end: 16px;
    }

    &__icon {
      color: $color-primary-light;
      inline-size: 3.5rem;
      block-size: 3.5rem;
      aspect-ratio: 1;
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      gap: 24px;
      align-self: stretch;
      margin-block-start: 24px;
    }
  }
}
