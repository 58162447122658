@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.market-history {
  grid-area: market-history;
  border: 1px solid $color-gray-400;
  position: relative;
  overflow: hidden;
  border-radius: $trade-page-border-radius;

  &__header {
    background: $color-gray-600;
    color: $color-white;
    font-size: 14px;
    font-weight: 400;
    padding: 13px;
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 4;
    display: flex;
    align-items: center;
    height: $card-header-height;
  }
  &__table-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.trade-date--tooltip {
  color: $color-white;
  font-size: 14px;
  line-height: 18px;
  white-space: nowrap;
  padding: 0.5rem;
}
