@import '@ping/assets/scss/theme/variables';

.socket-connectivity-status {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  &.is-partial {
    color: $color-warning-medium;
  }

  &.is-stable {
    color: $color-success-medium;
  }

  &.is-disconnected {
    cursor: pointer;
    color: $color-error-medium;
  }

  &--title {
    margin-left: 4px;
  }
}

.inspect {
  // inline-size: max-content;
  min-inline-size: 14rem;
  inset-inline-start: 90%;
  border-radius: 4px;
  color: $color-gray-100;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */

  p {
    margin: 0;

    &[data-error='false'] {
      color: $color-error;
    }
  }
}
