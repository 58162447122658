.slider-milestones {
  position: absolute;
  inset-block-start: 0;
  z-index: 0;
  inline-size: calc(100% + 2 * var(--track-inline-size-overflow));
  translate: calc(-1 * var(--track-inline-size-overflow)) -50%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
