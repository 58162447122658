@import '@ping/assets/scss/theme/variables';

.form-structure-stats-cell {
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: $color-gray-100;

  &:nth-of-type(even) {
    text-align: end;
    overflow-wrap: anywhere;
    text-wrap: balance;
  }
  &:nth-of-type(odd) {
    text-align: start;
  }
}
