@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';

.popover-trigger {
  inline-size: inherit;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  color: $color-gray-100;
  transition: $transition-hover;

  /* Body 01 / Regular */
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  &:hover,
  &:focus {
    color: $color-white;
  }
}
