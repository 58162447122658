@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/theme/theme';
@import '@ping/assets/scss/breakpoint';

.order-book {
  $order-book: &;

  grid-area: order-book;
  display: grid;
  grid-template-rows: min-content 1fr 1fr;
  border: 1px solid $color-gray-400;
  border-radius: $trade-page-border-radius;

  &__buy-sell {
    grid-template-rows: min-content 1fr;
  }

  &__heading {
    border-top-left-radius: $trade-page-border-radius;
    border-top-right-radius: $trade-page-border-radius;

    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $color-gray-600;
    height: $card-header-height;
    padding: 16px 12px;
  }
  &__title {
    /* 01 Body/semibold */
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    margin: 0;
    color: $color-white;
  }
  &__table {
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    thead {
      th {
        display: none !important;
      }
    }
  }

  table {
    display: flex;
    flex-direction: column;
    font-variant: tabular-nums;

    [class*='observer-element'] {
      height: 0;
    }

    &:not(.order-book__second-header) {
      height: inherit;
      tbody {
        justify-content: safe end;
        margin-top: auto;
      }
    }
    thead {
      width: inherit;
    }
    tbody {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    tr {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: inherit;
      height: $card-header-height;
      align-items: center;

      &[data-index='even'] {
        td span {
          background: $color-gray-700;
        }
      }
      &[data-indicated='true']::after {
        content: '';
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: 0;
        translate: 0 -50%;
        z-index: 1;
        inline-size: 0.25rem;
        block-size: 100%;

        border-radius: 0rem 1.25rem 1.25rem 0rem;
        background: $color-gray-200;
      }

      td span {
        background: $color-black;
      }

      #{$order-book}[data-order-kind='market'][data-order-side='buy'] &[data-record-side='sell'] {
        cursor: pointer;
      }
      #{$order-book}[data-order-kind='market'][data-order-side='sell'] &[data-record-side='buy'] {
        cursor: pointer;
      }

      th,
      td {
        display: flex;
        align-items: center;
        /* 01 Caption/regular */
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 133.333% */
        width: 33%;
        padding: 0 8px;
        height: fit-content;
      }

      th {
        &:hover {
          &[class*='headers-hover'] {
            path {
              fill: $color-gray-200;
            }
            span {
              color: $color-gray-200 !important;
            }
          }
        }
      }
    }
  }

  &__table-sell-container {
    thead {
      display: none;
    }
  }
  &__table-sell-only {
    display: block;
  }
  &__table-sell-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-400;
    background: $color-black;
    z-index: 1;
    position: sticky;
    top: 0;
    user-select: none;

    &__item {
      display: flex;
      align-items: center;
      height: 48px;
      width: 33%;
      padding: 8px;

      &:first-of-type {
        padding-inline: 16px 8px;
      }
      &:last-of-type {
        padding-inline: 8px 16px;
        justify-content: flex-end;
      }
    }
    &__text {
      background-color: $color-black;
      color: $color-gray-200;

      /* 01 Caption/regular */
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
    }
  }
  &__table-buy-container {
    overflow: hidden;

    .order-book__table {
      height: calc(100% - $card-header-height);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  &__empty-view {
    top: calc(50% + calc($card-header-height / 2));
  }
  &__button-container {
    display: flex;
    gap: 16px;
  }
  &__button {
    background: none;
    border: none;
    opacity: 0.5;
    padding: 0;
    &:hover,
    &.active-button {
      opacity: 1;
    }
    svg {
      fill: $color-white;
    }
  }

  &__icon {
    inline-size: 1.5rem;
    block-size: 1.5rem;
    aspect-ratio: 1;
  }
}

.order-book__second-header {
  overflow: auto;

  thead {
    display: none;
  }
}

.order-book__aggregate {
  position: sticky;
  bottom: 0;
  width: 100%;
}

.cell-price {
  cursor: pointer;
}

.cell-after {
  position: absolute;
  width: 50%;
  right: 0;
  top: 0;
  height: 100%;
  &.asks {
    background: rgba($color-error, 0.39);
  }
  &.bids {
    background: rgba($color-success, 0.39);
  }
}

@include media-breakpoint-up('sm') {
  .order-book {
    table {
      tr {
        th,
        td {
          padding: 0 16px;
          /* 02 Body/regular */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
        }
      }
    }
  }
}

.status--red {
  color: $color-error-light;
}

.status--green {
  color: $color-success-light;
}

@include media-breakpoint-up('sm') {
  .order-book {
    &__table-sell-header {
      &__item {
        padding: 8px 16px;

        &:first-of-type {
          padding-inline: 16px;
        }
        &:last-of-type {
          padding-inline: 16px;
        }
      }
      &__text {
        /* 02 Body/regular */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
      }
    }
  }
}

@include media-breakpoint-between('lg', 'xl') {
  .order-book__aggregate {
    font-size: 13px;
  }
}
