@import '@ping/assets/scss/breakpoint';

.order-form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media-breakpoint-between('sm', 'lg') {
    margin-block-start: 1.5rem; // tablet modal spacing
  }

  [class*='toggle-button-choice'] {
    padding: calc(0.5rem - 1px) 0.5rem;
  }
  [class*='tab'] {
    &[data-variant='secondary'] {
      padding: 0.75rem 0;
    }
  }
}

.trading-tabs-header {
  overflow: visible !important;
}
