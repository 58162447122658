@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.available-balance {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  border-bottom: 1px solid $color-gray-400;

  @include media-breakpoint-up('md') {
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    border-bottom: none;
  }

  &__texts {
    display: flex;
    align-items: center;
    gap: 0.75rem;

    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    color: $color-gray-200;
  }

  &__cell {
    display: inline-block;
    white-space: nowrap;
    overflow-x: auto;

    &:nth-of-type(odd) {
      color: $color-gray-100;
      text-align: start;
    }
    &:nth-of-type(even) {
      color: $color-white;
      text-align: end;
    }
  }

  &__divider {
    margin: 0;
    border: none;
    border-block-start: 0.5px solid $color-gray-300;
  }

  &__amount {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    white-space: nowrap;
    overflow-x: auto;
    color: $color-white;
  }
}
