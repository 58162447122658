@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.market-summary {
  margin: 0;
  background: $color-black;
  border-block: 1px solid $color-gray-300;
  font-variant: tabular-nums;

  align-self: stretch;
  display: flex;
  align-items: center;

  &__group {
    padding: 8px;
    flex: 1 0 0;
    align-self: stretch;
    width: 33%;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    align-items: flex-start;
    gap: 0.5rem;

    &:first-of-type {
      align-items: flex-start;
      padding-inline: 16px 8px;
    }
    &:last-of-type {
      align-items: flex-end;
      padding-inline: 8px 16px;
    }
  }

  &__title {
    margin: 0;
    color: $color-gray-200;
    text-align: center;
    white-space: nowrap;
    background-color: $color-black;

    /* 01 Caption/regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }

  &__value {
    margin: 0;
    color: $color-white;
    text-align: center;
    white-space: nowrap;
    transition: color 0.12s ease;
    background-color: $color-black;

    /* 01 Caption/regular */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */

    &[data-fluctuation='idle'] {
      color: $color-white;
    }
    &[data-fluctuation='up'] {
      color: $color-success;
    }
    &[data-fluctuation='down'] {
      color: $color-error;
    }
  }
}

@include media-breakpoint-up('sm') {
  .market-summary {
    &__title,
    &__value {
      /* 02 Body/regular */
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
    &__group {
      padding: 8px 16px;

      &:first-of-type {
        padding-inline: 16px;
      }
      &:last-of-type {
        padding-inline: 16px;
      }
    }
  }
}
