@import '@ping/assets/scss/theme/variables';

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 24px;
  display: flex;
  padding: 4px 16px;
  border-top: 0.5px solid $color-gray-300;
  background: $color-black;
  justify-content: space-between;
  z-index: 901;

  &--right-side,
  &--left-side {
    display: inline-flex;
    flex-direction: row;
  }

  &--links {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: $color-gray-200;
    transition: all 400ms ease;
    cursor: pointer;
    padding: 0 10px;

    &:hover {
      color: $color-white;
      text-decoration: none;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.regulator--modal {
  &-header {
    justify-content: flex-end;
    padding-block: 8px;
    padding-inline: 8px;
  }

  &-title {
    margin-bottom: 8px;
  }

  &-content {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $color-gray-100;
  }
}

.tooltip--content {
  inline-size: 288px !important;
  border-radius: 4px;
  border: 1px solid $color-gray-500;
  background: $color-gray-600;
  color: $color-gray-100;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
}
