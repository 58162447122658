@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.form-submit {
  text-transform: uppercase !important;
  border-radius: 3px;
  padding-inline: 1.5rem;
  block-size: 2.5rem;
  height: 40px !important;
  user-select: none;
  transition: all 0.3s ease-in-out;

  &:disabled {
    transition: none;
    opacity: 0.4;
    color: $color-white !important;
  }

  &[data-side='sell'] {
    background: $color-error;
    border-color: $color-error;

    &:hover,
    &:disabled {
      background: $color-error-medium;
      border-color: $color-error-medium;
    }
  }
  &[data-side='buy'] {
    background: $color-success;
    border-color: $color-success;

    &:hover,
    &:disabled {
      background: $color-success-medium;
      border-color: $color-success-medium;
    }
  }
}
